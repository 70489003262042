import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Overdrafts from "../../components/body/pages/bill-payments/overdrafts"

const OverdraftsPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/overdrafts"}
      title="Overdrafts | Flexible Finance | Kuda"
      description="Access flexible overdrafts to manage unexpected expenses with ease. Discover how Kuda's overdrafts offer financial flexibility & peace of mind. Join Kuda!"
    />
    <Overdrafts />
  </Layout>
)

export default OverdraftsPage
